import GetCustomerConfiguration from '../Customers/Customer';
import SignupBasicLayout from './SignupLayouts/SignupBasicLayout';
import SignupStandardLayout from './SignupLayouts/SignupStandardLayout';
import LanguageSelectLayout from './DefaultLayouts/LanguageSelectLayout';
import SignupBasicLayoutPlayground from './SignupLayouts/SignupBasicLayoutPlayground';

const LayoutSwitcher = (CID, Layout, WhatLanguage, WhatDevices)  => {        
    switch(Layout){
        case "Basic": return <SignupBasicLayout CustomerID={CID} Language={WhatLanguage} Devices={WhatDevices} />;
        case "Standard": return <SignupStandardLayout CustomerID={CID} Language={WhatLanguage} Devices={WhatDevices}/>;
        case "Playground": return <SignupBasicLayoutPlayground CustomerID={CID} Language={WhatLanguage} Devices={WhatDevices} />; 
        default: return <LanguageSelectLayout />;
    }
}

export default function Signup() {      
    const Customer = GetCustomerConfiguration();    
    return ( 
        <div>{LayoutSwitcher(Customer.CustomerID, Customer.SignupLayout, Customer.Language, Customer.Devices)}</div>        
    );        
};