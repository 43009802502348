import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright @ '}
            <Link color="inherit" target="_blank" href="https://www.vibe-hearing.com/">
                Vibe
      </Link>{' '}
            {new Date().getFullYear()}
            {/*{' '}
            //{process.env.VERSION}
    */}
        </Typography>
    );
}

export default Copyright;