import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    textfield: {
        fontWeight: "400",
        "&$Mui-disabled": {
            color: "red"
        }
    },
    disabled: {}
}));


export default function TermsAndConditions() {

    return (
        <div>
            <Typography component="h1" variant="h6" >
            Under construction
                </Typography>
                <Typography component="h1" variant="h6" >
                1.  Under construction
                </Typography>
            <Typography component="h1" variant="body1" >
                1.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                1.2  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                1.3  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                1.3.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                1.3.2  Under construction
                </Typography>
                <Typography component="h1" variant="h6" >
                2.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="h6" >
                3.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                3.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                3.1.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                3.2  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                3.3  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                3.3.1  Under construction
                </Typography>
                <Typography component="h1" variant="h6" >
                4.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                4.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                4.2  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                4.3  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                4.3.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                4.3 Under construction
                </Typography>
                <Typography component="h1" variant="h6" >
                5.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.1.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.2  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.3  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.4  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.5  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.6  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.7  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                5.1.8  Under construction
                </Typography>
                <Typography component="h1" variant="h6" >
                6.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                6.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                6.2  Under construction
                </Typography>
                <Typography component="h1" variant="h6" >
                7.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                7.1  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="h6" >
                8.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                9.  Under construction
                </Typography>
                <Typography component="h1" variant="body1" >
                Under construction
                </Typography>
        </div>
    );
}