
import GetCustomerConfiguration from '../Customers/Customer.js';
import RedirectBasicLayout from './RedirectLayouts/RedirectBasicLayout.js';
import RedirectBasicLayoutPlayground from './RedirectLayouts/RedirectBasicLayoutPlayground.js';
import ETPhoneHomeLayout from "./DefaultLayouts/ETPhoneHome.js";

// Summary LayoutSwitcher function
const LayoutSwitcher = (Layout, WhatLanguage, WhatSBLink)  => {        
    switch(Layout){
        case "RedirectBasicLayout": return <RedirectBasicLayout Language={WhatLanguage} SBURL={WhatSBLink} />;
        case "Playground": return <RedirectBasicLayoutPlayground Language={WhatLanguage} SBURL={WhatSBLink} />;
        default: return <ETPhoneHomeLayout />;
    }
}

export default function Redirect(props) {   
    const Customer = GetCustomerConfiguration();      
    console.log(Customer)
    return ( 
        <div>{LayoutSwitcher(Customer.RedirectLayout, Customer.Language, Customer.SBURL)}</div>        
    );
};
