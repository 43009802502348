import React from 'react';
import { useNavigate } from 'react-router-dom';

// UI imports
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
//import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
//import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// Graphics
import vibeImg from "./../../Assets/vibetransparent.png";
import serial from "./../../Assets/SerialNo.jpg";

import Copyright from '../DefaultLayouts/Copyright';
import TranslateThis from '../../translations/TranslateThis';
import { EncodeBase64 } from '../../Utils';



const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        },

        // "& .MuiFormHelperText-root": {
        //     color: theme.palette.primary.light,
        // },

        // "& .MuiFormHelperText-root Mui-error": {
        //     color: "red",
        // }
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        height: "56px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    popupimg: {
        width: '90%',
    },
}));


export default function SignupBasicStyle({CustomerID, Language, Devices}) {

    let navigate = useNavigate();    
    const classes = useStyles();    
    
    const Translation = TranslateThis(Language);

    var [leftDevice, setLeftDevice] = React.useState('');
    var [rightDevice, setRightDevice] = React.useState('');
    const [helperTextLeftAndRightEmpty, setHelperTextLeftAndRightEmpty] = React.useState('');
    const [deviceNameDialogOpen, setDeviceNameDialogOpen] = React.useState(false);   
    const [startHearingCheckDisabled, setStartHearingCheckDisabled] = React.useState(true);
    const handleDeviceNameDialogClickOpen = () => {
        setDeviceNameDialogOpen(true);
    };

    const validate = (DeviceLeft, DeviceRight) => {
        let showHelperText = (DeviceLeft === "" || DeviceLeft === "0") && (DeviceRight === "" || DeviceRight === "0")
        let valid = !(DeviceLeft === "" || DeviceLeft === "0") || !(DeviceRight === "" || DeviceRight === "0");        
        return [valid, showHelperText];
    }

    const handleClose = (value) => {        
        setDeviceNameDialogOpen(false);
    };

    const onSubmit = (event) => {
        event.preventDefault(); 

        if (validate(leftDevice, rightDevice)) {
            console.log(leftDevice);        
            console.log(rightDevice);

            let leftDev = leftDevice;
            let rightDev = rightDevice;

            if (leftDev === "0")
                leftDev = "";

            if (rightDev === "0")
                rightDev = "";

            let refid = CustomerID + ";" + leftDev + ";" + rightDev + ";firstfit";
            let refid64encoded = EncodeBase64(refid);

            console.log(refid64encoded);            
            navigate("/redirect" + refid64encoded, { replace: true});    
        }
        else
        {
            console.log("Validation failed!");
        }
    }

    return ( 
        <Container component="main" maxWidth="md">            
            <CssBaseline />
            <div className={classes.paper}>
                <img className={classes.logo} alt="Vibe" src={vibeImg} />
                {/* <Typography component="h1" variant="h4" style={{ marginBottom: "25px" }}>
                    Vibe First Fit
                 </Typography> */}
                <Typography component="h1" variant="body1" style={{ marginTop: "25px" }}>
                {Translation.signup_intro_text}             
                </Typography>            

                <Typography component="h1" variant="h6" style={{ marginTop: "25px", background: "#e0e6ea", width: "100%", borderRadius: "5px" }} align="center">
                {Translation.signup_select_device_step_title}
                </Typography>

                <form className={classes.form} onSubmit={onSubmit}>                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="subtitle1" align="center">
                                    {Translation.left_ear}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} 
                                        fullWidth>
                                        <Select
                                            labelId="demo-simple-select-placeholder-label-label"
                                            id="demo-simple-select-placeholder-label"
                                            value={leftDevice}
                                            onChange={ (event) => {
                                                const validation = validate(event.target.value, rightDevice);                                                
                                                setHelperTextLeftAndRightEmpty(validation[1] ? Translation.please_choose_at_least_one_model : ''); 
                                                setStartHearingCheckDisabled(!validation[0]);                                                
                                                setLeftDevice(event.target.value);                                                
                                                }                                                                                                             
                                            }
                                            className={classes.selectEmpty}>
                                            <MenuItem key={0} value="0">
                                                <em>{Translation.selection_none}</em>                                                                                
                                            </MenuItem>     
                                            {Devices.map((Device, index) => <MenuItem key={index+1} value={Device[0]}> {Device[1]} </MenuItem>
                                            )}                                                                                                                           
                                        </Select>
                                        <FormHelperText style={{ color: "#839ca9" }}>{Translation.hearing_aid_for_left_ear}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="subtitle1" align="center">
                                    {Translation.right_ear}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} displayEmpty
                                        fullWidth>
                                        <Select
                                            labelId="demo-simple-select-placeholder-label-label"
                                            id="demo-simple-select-placeholder-label"
                                            value={rightDevice}
                                            onChange={(event) => {                                                
                                                const validation = validate(leftDevice,event.target.value);
                                                setHelperTextLeftAndRightEmpty(validation[1] ? Translation.please_choose_at_least_one_model : ''); 
                                                setStartHearingCheckDisabled(!validation[0]);                                                
                                                setRightDevice(event.target.value);  
                                            }}

                                            className={classes.selectEmpty}>
                                            <MenuItem key="0" value="0">
                                                <em>{Translation.selection_none}</em>
                                            </MenuItem>
                                            {Devices.map((Device, index) => <MenuItem key={index+1} value={Device[0]}> {Device[1]} </MenuItem>
                                            )}    
                                        </Select>
                                        <FormHelperText style={{ color: "#839ca9" }}>{Translation.hearing_aid_for_right_ear}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography component="h1" variant="body1" align="center" style={{ color: "red" }}>
                    {helperTextLeftAndRightEmpty}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: "15px", cursor: "pointer" }}>
                        <Link onClick={handleDeviceNameDialogClickOpen}>{Translation.check_hearing_model_dialog_link}</Link>
                    </Typography>
                    <Button                        
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"                        
                        disabled={startHearingCheckDisabled}
                        className={classes.submit}>
                        {Translation.start_hearing_test}
                    </Button>
                </form>
            </div>
            <Box mt={5}>                
                <Copyright />
            </Box>            
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={deviceNameDialogOpen} style={{ padding: "25px" }}>
                <Box
                    mt={2}
                    mb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <img className={classes.popupimg} alt="Vibe" src={serial} />
                </Box>
                <Typography align="center" component="h1" variant="body1" style={{ marginTop: "10px" }}>
                {Translation.dialog_check_hearing_model_sticker}
                </Typography>
                <Button                    
                    type="submit"
                    fullWidth
                    color="primary"                    
                    className={classes.submit}                    
                    onClick={handleClose}>
                    {Translation.close_text}
                    </Button>
            </Dialog>            
        </Container>
    );
};


