import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from './Copyright';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import vibeImg from "./../../Assets/vibetransparent.png";

import TranslateThis from '../../translations/TranslateThis';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        }
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    popupimg: {
        width: '90%',
    },
}));


export default function ETPhoneHomeLayout() {      
    const classes = useStyles();    
    
    // Default English text    
    const Translation = TranslateThis("en-us");
    
    return ( 
        <Container component="main" maxWidth="md">            
            <CssBaseline />
            <div className={classes.paper}>
                <img className={classes.logo} alt="Vibe" src={vibeImg} />
                <Typography component="h1" variant="body1" style={{ marginTop: "25px" }}>
                {Translation.No_Such_Customer_text}             
                </Typography>                          
            </div>
            <Box mt={5}>                
                <Copyright />
            </Box>                                 
        </Container>
    );
};