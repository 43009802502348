import React from 'react';
import AppRoutes from './Components/AppRoutes'
import { BrowserRouter } from 'react-router-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary:{
      main: '#00B4BC'
    },
    badlink:{
      main: '#FF3333'
    },
    secondary: {
        main: '#5D7D84'
      }
    },
    action: {
      disabledBackground: '#5D7D84',
      disabled: '#5D7D84'
    }
  },
)

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>    
  </MuiThemeProvider>
  );
}

export default App;
