import { Route, Routes } from "react-router-dom";
import Signup from "./Signup";
import Redirect from "./Redirect";
import Summary from './Summary';

function AppRoutes() {
  return (
    <Routes>
        <Route exact path="/" element={<Signup />} />        
        <Route exact path="/redirect" element={<Redirect />} />      
        <Route exact path="/summary" element={<Summary />} />        
    </Routes>
  );
};

export default AppRoutes;   
