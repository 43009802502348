import en_us from './en-us.json';
import id_id from './id-id.json';
import vi_vi from './vi-vi.json';
import ja_jp from './ja-jp.json';
import ko_kr from './ko-kr.json';
import zh_ch from './zh_ch.json';


export default function TranslateThis(Language) {                  
    switch(Language){
        case "en-us": return en_us;
        case "id-id": return id_id;
        case "vi-vi": return vi_vi;
        case "ja-jp": return ja_jp;
        case "ko-kr": return ko_kr;
        case "zh-ch": return zh_ch;
        default: return en_us;
    }    
}; 