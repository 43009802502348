
import GetCustomerConfiguration from '../Customers/Customer.js';
import SummaryDirectLayout from './SummaryLayouts/SummaryDirectLayout.js';
import SummaryEmailLayout from './SummaryLayouts/SummaryEmailLayout.js';
import SummarySMSLayout from "./SummaryLayouts/SummarySMSLayout.js";
import SummaryDirectLayoutPlayground from "./SummaryLayouts/SummaryDirectLayoutPlayground.js"
import ETPhoneHomeLayout from "./DefaultLayouts/ETPhoneHome.js";

// Summary LayoutSwitcher function
const LayoutSwitcher = (Layout, WhatLanguage, WhatMyVibeLink)  => { 
    console.log(Layout)       
    switch(Layout){
        case "SummaryDirectLayout": return <SummaryDirectLayout Language={WhatLanguage} MyVibeLink={WhatMyVibeLink} />;
        case "SummaryEmailLayout": return <SummaryEmailLayout Language={WhatLanguage} MyVibeLink={WhatMyVibeLink} />;
        case "SummarySMSLayout": return <SummarySMSLayout Language={WhatLanguage} MyVibeLink={WhatMyVibeLink} />;
        case "Playground": return <SummaryDirectLayoutPlayground Language={WhatLanguage} MyVibeLink={WhatMyVibeLink} />;
        default: return <ETPhoneHomeLayout />;
    }
}

export default function Summary(props) {   
    const Customer = GetCustomerConfiguration();      
    console.log(Customer)
    return ( 
        <div>{LayoutSwitcher(Customer.SummaryLayout, Customer.Language, Customer.MyVibeLink)}</div>        
    );
};
