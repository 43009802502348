import CustomerData from './Customers.json';
import GetQueryParameterValue, {GetQueryDecodedAndSplit} from '../Utils'
   
// Get CustomerID 
export default function GetCustomerConfiguration() {
    let URLCustomerID = GetQueryParameterValue("ID");    

    if(URLCustomerID === undefined || URLCustomerID === null){    
        let URLRefID = GetQueryDecodedAndSplit("refId");
        URLCustomerID = URLRefID[0]; // First element is always the CustomerID        
    }

    // Will loop through Customers.json and pair up "CustomerID" === URLCustomerID, and
    // return that Customer block
    var SpecificCustomer = CustomerData.find((arrElm) => arrElm.CustomerID === URLCustomerID);  

    // In case of undefinced customer, return default "nobody"
    return (SpecificCustomer === undefined) || (SpecificCustomer === null) ? CustomerData.find((arrElm) => arrElm.CustomerID === "-1") : SpecificCustomer;  
}