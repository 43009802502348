
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from './Copyright';
import Typography from '@material-ui/core/Typography';
import vibeImg from "./../../Assets/vibetransparent.png";
import TranslateThis, { TranslationList } from '../../translations/TranslateThis';
import { useNavigate } from 'react-router-dom';

import * as React from 'react';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CustomerData from '../../Customers/Customers.json';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        }
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    popupimg: {
        width: '90%',
    },
}));


export default function LangaugeSelectLayout() {      
    const classes = useStyles();    
    const navigate = useNavigate();
    
    // Default English text    
    const Translation = TranslateThis("en-us");

    const [Language, setLanguage] = React.useState('');

    const handleLanguage = (event) => {        
        setLanguage(event.target.value);
    };

    const handleLanguageSelected = (event) => {
        let params = "?ID=" + Language
        navigate("/" + params, { replace: true });   
    }
       
    return ( 
        <Container component="main" maxWidth="md">            
            <CssBaseline />
            <div className={classes.paper}>
                <img className={classes.logo} alt="Vibe" src={vibeImg} />
                <br />
                <Typography>Please select a language and click NEXT</Typography>
                <br />

                <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Language</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Language}
                            label="Language"
                            onChange={handleLanguage}>       
                            {CustomerData.slice(0, CustomerData.length - 2).map((Customer, index) => <MenuItem key={index} value={Customer.CustomerID}> {Customer.LanguageName} </MenuItem> )} 
                        </Select>
                        <br />
                        <Button                            
                            fullWidth
                            variant="contained"
                            color="primary"                            
                            className={classes.submit}
                            onClick={handleLanguageSelected}>
                            NEXT
                        </Button>
                    </FormControl>
                </Box>
            </div>
        </Container>                
    );
};