import { useLocation } from "react-router-dom";
import { Base64 } from 'js-base64';
import { encode } from 'url-safe-base64';

export default function GetQueryParameterValue(ParameterName) {
    return new URLSearchParams(useLocation().search).get(ParameterName);
}

export function EncodeBase64(input){    
    let dataEncoded = Base64.encode(input);
    let base64UrlSafeEncoded = encode(dataEncoded);
    return encodeURI('?refId=' + base64UrlSafeEncoded);
}

export function GetQueryDecodedAndSplit(ParameterName){
    let refid = GetQueryParameterValue(ParameterName);   
    return (refid === undefined) || (refid === null) ? "" : Base64.decode(refid).split(";");
}