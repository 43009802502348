import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import vibeImg from "./../../Assets/vibetransparent.png";
import Button from '@material-ui/core/Button';

import TranslateThis from '../../translations/TranslateThis';
import GetQueryParameterValue from '../../Utils.js'


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    textfield: {
        fontWeight: "400",
        "&$Mui-disabled": {
            color: "red"
        }
    },
    disabled: {}
}));


export default function SummaryEmailLayout(props) {
    const classes = useStyles();

    const Translation = TranslateThis(props["Language"]); 

    const [readyToCustomize, setReadyToCustomize] = React.useState(false)
    const [showCircularProgress, setShowCircularProgress] = React.useState(<CircularProgress style={{ marginTop: "50px" }} color="secondary" />)
    setTimeout(() => {
                        setReadyToCustomize(true);
                        setShowCircularProgress(<div/>);
                     },70000)
    
    const navigate = () => {              
        let urlMyVibeLink = props["MyVibeLink"] + GetQueryParameterValue("participant_test_id");                         
        window.location.replace(urlMyVibeLink);
    }

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>                
                <img className={classes.logo} alt="Vibe" src={vibeImg} />
                {/* <Typography component="h1" variant="h4" style={{ marginBottom: "25px" }}>
                    Vibe First Fit
                 </Typography> */}
                <Typography component="h1" variant="body1" style={{ marginTop: "50px" }}>
                {Translation.left_ear}
                Vui lòng nhấn nút bên dưới để bắt đầu cá nhân hóa máy trợ thính của bạn.
                </Typography>
                <Typography component="h1" variant="body1" style={{ marginTop: "5px" }}>
                Cảnh báo: Nếu bạn đóng trình duyệt bây giờ, bạn sẽ cần thực hiện lại bài kiểm tra thính lực.                
                </Typography>
                <Typography component="h1" variant="body1" style={{ marginTop: "5px" }}>
                Vui lòng kiên nhẫn trong khi hệ thống đang cài đặt thông số cho riêng bạn (trong vòng một phút)
                </Typography>
                <Typography component="h1" variant="body1" style={{ marginTop: "5px" }}>
                {showCircularProgress}
                <Button type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    className={classes.submit}
                    onClick={navigate}
                    disabled={readyToCustomize?"":"true"}>
                    Cá nhân hóa máy trợ thính ngay bây giờ
                    </Button>
                </Typography>                
            </div>
        </Container>
    );
}