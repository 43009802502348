import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import vibeImg from "./../../Assets/vibetransparent.png";
import Button from '@material-ui/core/Button';

import TranslateThis from '../../translations/TranslateThis';
import GetQueryParameterValue from '../../Utils';

import axios from 'axios';


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: "#FAFAFA",
        },

        "& .MuiFormLabel-root.Mui-disabled": {
            color: theme.palette.primary.light,
        },

        "& .MuiFormLabel-root": {
            color: theme.palette.primary.light,
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.light,
        },
    },
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    logo: {
        width: '15vw',
        minWidth: "100px"
    },
    textfield: {
        fontWeight: "400",
        "&$Mui-disabled": {
            color: "red"
        }
    },
    disabled: {}
}));


function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

const config = {withCredentials: true}
var GotLink = false

export default function SummaryDirectLayout(props) {
    const classes = useStyles();

    const Translation = TranslateThis(props["Language"]); 
    const urlMyVibeLink =  props["MyVibeLink"] +  GetQueryParameterValue("participant_test_id");         

    const [readyToCustomize, setReadyToCustomize] = React.useState(false);
    const [showCircularProgress, setShowCircularProgress] = React.useState(<CircularProgress style={{ marginTop: "50px" }} color="secondary" />);
    const [myVibeLink, setMyVibeLink] = React.useState("NORESULT")

    const keepCalling = (iteration) => {
        setTimeout( async () =>{
            setReadyToCustomize(false);
            setShowCircularProgress(<CircularProgress style={{ marginTop: "50px"}} color="secondary" />)

            //Try to fetch link            
            const data = axios.get(urlMyVibeLink,config).then((data) => {
                //console.log(data.status)            
            
                if(iteration > 0){
                    if((data.status === 200) && validURL(data.data)){      
                        GotLink = true                  
                        //console.log("GOT THE LINK: " + data.data)
                        setReadyToCustomize(true)
                        setShowCircularProgress(<div/>)
                        setMyVibeLink(data.data)                                                                    
                    }                                        
                }
                else
                {    
                    //console.log("Timed out!!!!")
                    setShowCircularProgress(<div><p>TIMED OUT!</p></div>)
                }   
                
                if (!GotLink && (iteration > 0))            
                    keepCalling(iteration - 1);   

            }).catch((err) => {                
                console.log("Catch Error for MyVibeLink request")

                if ((iteration > 0))            
                    keepCalling(iteration - 1);                          
            })            
        }, 3000)
    }

    React.useEffect(() => {
        keepCalling(15)
        // eslint-disable-next-line
    },[])

    const GoNavigate = () => {              
        console.log(myVibeLink)
        window.location.replace(myVibeLink);
    }
    
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>                
                <img className={classes.logo} alt="Vibe" src={vibeImg} />
                 <Typography component="h1" variant="body1" style={{ marginTop: "50px" }}>
                {Translation.summary_text}                
                </Typography>                
                <Typography component="h1" variant="body1" align="center" style={{ marginTop: "5px" }}>
                {showCircularProgress}
                <Button type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    className={classes.submit}
                    onClick={GoNavigate}
                    disabled={readyToCustomize?"":"true"}>
                    {Translation.summary_personalize_button_text}                    
                    </Button>
                </Typography>                
            </div>
        </Container>
    );
}